<template>
	<main class="page-container">
		<section class="detail-section" data-fill="true">
			<div class="container">
				<div class="page-header">
					<h3 class="page-header-title"> {{ sentContCtgrDcdVal }} </h3>
				</div>
				<!-- 제목 -->
				<div class="detail-section-header">
					<p class="detail-section-header__sub">{{ sentContSmlTit }}</p>
					<div class="detail-section-header__title">
						<p class="title">{{ sentContTit }}</p>
						<span class="date"></span>
					</div>
				</div>
				<!-- // 제목 -->

				<!-- VOD player -->
				<!-- <div class="vod-wrap">
					<iframe name="myplayer" id="myplayer" :src="playerUrl" frameborder="0" width="600" height="400" allow="encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div> -->
				<!-- //VOD player -->

				<!-- VOD 공지 -->
				<!-- <div class="detail-section-content" v-html="sentContCn" ></div> -->
				<div v-html="sentContCn" ></div>
				<!-- //VOD 공지 -->

				<div class="detail-section-footer">
					<div class="buttons">
						<router-link v-if="preId" :to="`/sentcont/${sentContCtgrDcd}/view/${preId}`" class="button-default is-large is-rounded is-gray">이전</router-link>
						<router-link :to="`/sentcont/${sentContCtgrDcd}/list`" class="button-default is-large is-rounded is-secondary">목록</router-link>
						<router-link v-if="nextId" :to="`/sentcont/${sentContCtgrDcd}/view/${nextId}`" class="button-default is-large is-rounded is-gray">다음</router-link>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_SENTCONT_INFO } from '@/store/_act_consts';
import { getItem, timestampToDateFormat } from '@/assets/js/utils';

export default {
	name: 'InfoDetail',
	components: {},
	computed: {
		//...mapGetters('auth', ['isAuth', 'session', 'profile', 'creatorModal']),
	},
	watch: {
		$route(to) {
      this.sentContCtgrDcd = to.params.sentContCtgrDcd;
      this.sentContId = to.params.sentContId;
		},
    sentContId() {
			this.getSentContInfo();
		},
	},
	mounted() {},
	data: () => ({
    sentContCtgrDcd: 1,
    sentContId: 1,
    sentContCtgrDcdVal: '',
    sentContSmlTit: '',
    sentContTit: '',
    regDt: '',
    sentContCn: '',
    preId: 0,
    nextId: 0,
	}),
	created() {
    this.sentContCtgrDcd = this.$route.params.sentContCtgrDcd;
    this.sentContId = this.$route.params.sentContId;
    this.getSentContInfo();
  },
	methods: {
    getSentContInfo() {
			//소상공인컨텐츠 불러오기
			this.$store.dispatch(`sentcont/${ACT_GET_SENTCONT_INFO}`, {
        sentContId: this.sentContId,
        sentContCtgrDcd: this.sentContCtgrDcd,
      })
      .then((res) => {
        const item = getItem(res);
        this.sentContCtgrDcdVal = item.sentContCtgrDcdVal;
        this.sentContSmlTit = item.sentContSmlTit;
        this.sentContTit = item.sentContTit;
        this.regDt = this.regDtTimeFormat(item.regDt);
        this.sentContCn = item.sentContCn;
        this.preId = item.preId;
        this.nextId = item.nextId;
      });
		},
    regDtTimeFormat(regDt) {
			return timestampToDateFormat(regDt, 'yyyy.MM.dd');
		},
  },
};
</script>
